import React, {useMemo} from 'react';
import ETFHeader from './ETFHeader';
import ETFPanel from './ETFPanel';
import SectorStrikeContext from '../../Context/SectorStrikeContext';

const ETFView = ({layout, groupCollection, currentDate, groups, optionsColumns, optionsAllowed, lockedColumns}) => {
  const contextValue = useMemo(
    () => ({
      optionsColumns,
      optionsAllowed,
      lockedColumns,
      layout,
      groupCollection,
      currentDate,
      groups,
    }),
    [optionsColumns, optionsAllowed, lockedColumns, layout, groupCollection, currentDate, groups],
  );
  return (
    <SectorStrikeContext.Provider value={contextValue}>
      <ETFHeader currentDate={currentDate} etfGroups={groups} />
      <div className="etf-view flex flex-col w-full h-full gap-2 overflow-y-scroll">
        <div className="flex flex-row w-full h-full gap-4 etf-top-row">
          <div className="flex-1 w-1/2" id="panel1">
            <ETFPanel panel={layout.panel1} />
          </div>
          <div className="flex-1 w-1/2" id="panel2">
            <ETFPanel panel={layout.panel2} />
          </div>
        </div>
        <div className="w-full etf-bottom-row">
          <div id="panel3">
            <ETFPanel panel={layout.panel3} />
          </div>
        </div>
      </div>
    </SectorStrikeContext.Provider>
  );
};

export default ETFView;
