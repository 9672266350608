/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import Chart from 'chart.js/auto';
import dayjs from 'dayjs';
import {isEqual, orderBy as lodashOrderBy} from 'lodash';

const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.extend(customParseFormat);

const BarChart = ({data = [], viewLayout, currentDate}) => {
  const [xAxisValues, setXAxisValues] = useState([]);
  const [yAxisValues, setYAxisValues] = useState([]);
  const [chart, setChart] = useState(null);

  /**
   * Create xAxis headers and sort headers by orderBy
   * @param {array} tableData - Array of objects with ticker data
   * @param {object} xAxis - xAxis options
   * @param {string[]} xAxis.keys - Array of keys to use for xAxis
   * @param {string} xAxis.orderBy - Key to order xAxis by
   * @returns {array}
   */
  const setupXAxis = (tableData, xAxis) => {
    const {keys: xAxisKeys, orderBy} = xAxis;
    if (!xAxisKeys?.length) {
      return [];
    }

    const xAxisLabelsReduced = tableData.reduce((acc, ticker) => {
      xAxisKeys.forEach((key) => {
        if (ticker?.[key]?.value) {
          acc.push({
            label: ticker?.[key]?.value,
            key,
            orderBy: Number(ticker?.[orderBy ?? 'nb60dChange']?.value) ?? 0,
          });
        }
      });
      return acc;
    }, []);

    const SPYorSPX = xAxisLabelsReduced.findIndex((h) => h.label === 'SPY' || h.label === 'SPX');
    if (SPYorSPX > -1) {
      // Move to end of array
      const removedFromArray = xAxisLabelsReduced.splice(SPYorSPX, 1)[0];
      const sortedXAxisLabels = lodashOrderBy(xAxisLabelsReduced, ['orderBy'], ['asc']);
      return [...sortedXAxisLabels, removedFromArray].map((label) => label.label);
    }
    return lodashOrderBy(xAxisLabelsReduced, ['orderBy'], ['asc']).map((label) => label.label);
  };

  /**
   * Create yAxis headers and sort headers by orderBy
   * @param {object} d - Data to use for yAxis
   * @param {object} yAxis - yAxis options
   * @param {string[]} yAxis.keys - Array of keys to use for yAxis
   * @param {string[]} xAxisLabels - Array of xAxis labels
   * @param {string} date - Date to use for yAxis
   * @returns {array}
   */
  const setupYAxis = (d, yAxis, xAxisLabels, date) => {
    let yAxisLabels = [];
    if (yAxis?.keys?.length) {
      const {bars, type, initialInterval, interval} = yAxis;
      if (type === '30d-time-period') {
        const timePeriods = [];
        let numOfTimePeriods = bars.length;
        let initialTimePeriod = initialInterval;
        let index = 0;
        const today = date ? dayjs(date, 'MM-DD-YYYY') : dayjs();
        while (numOfTimePeriods) {
          const label = !initialTimePeriod
            ? today.add(1, 'month').startOf('month').format('MMMM')
            : today.subtract(initialTimePeriod, 'days').add(1, 'month').startOf('month').format('MMMM');

          timePeriods.push({label, key: bars[index]});

          numOfTimePeriods -= 1;
          index += 1;
          initialTimePeriod += interval;
        }
        if (timePeriods?.length) {
          yAxisLabels = timePeriods.map((tPeriod, i) => {
            const format = {
              label: tPeriod?.label,
              data: [],
              backgroundColor: i % 2 === 0 ? '#fded00' : '#007c8c',
            };
            xAxisLabels.forEach((xLabel) => {
              if (d?.[xLabel]?.[tPeriod?.key]?.value && !Number.isNaN(Number(d?.[xLabel]?.[tPeriod?.key]?.value))) {
                format.data.push(Number(d?.[xLabel]?.[tPeriod?.key]?.value) / 100);
              } else {
                format.data.push(0);
              }
            });
            return format;
          });
        }
      }
    }
    return yAxisLabels;
  };

  /**
   * Configure data to chart data
   * @param {object} d - Data to use for chart
   * @returns {object} - Object with xAxis and yAxis labels
   */
  const configureMongoDataToChartData = (d) => {
    const tableData = Object.values(d ?? {});
    const {xAxis, yAxis} = viewLayout;
    if (!tableData?.length) {
      return {xAxisLabels: [], yAxisLabels: []};
    }
    const xAxisLabels = setupXAxis(tableData, xAxis);
    const yAxisLabels = setupYAxis(d, yAxis, xAxisLabels, currentDate);

    return {xAxisLabels, yAxisLabels};
  };

  /**
   * Initialize chart package
   * @param {object} d - Data to use for chart
   */
  const setupBarChart = (d) => {
    if (chart) return;
    const {xAxisLabels, yAxisLabels} = configureMongoDataToChartData(d);
    setXAxisValues(xAxisLabels);
    setYAxisValues(yAxisLabels);
    const plugin = {
      id: 'customCanvasBackgroundColor',
      afterDraw: (ch, args, options) => {
        const {ctx} = ch;
        if (!ctx) return;
        const {_metasets: metasets} = ch;
        if (!metasets?.length) return;
        if (metasets.length < 2) return;
        if (!metasets[0]?.data?.length || !metasets[1]?.data?.length) return;
        const {padding, backdropPadding} = ch?.options?.scales?.x?.ticks ?? {padding: 3, backdropPadding: 2};
        const totalPadding = padding + backdropPadding;
        const {x: x1, width: width1} = metasets[0].data[metasets[0].data.length - 1];
        const {x: x2, width: width2} = metasets[1].data[metasets[1].data.length - 1];
        ctx.save();

        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = options?.color ?? '#02020E';
        ctx.fillRect(
          x1 - width1 + totalPadding, // x
          ch.scales.y.getPixelForTick(ch.scales.y.getTicks()?.length - 1), // y
          x2 + width2 - x1 + width1 - (totalPadding * 2), // width
          ch.scales.y.bottom, // height
        );
        window.ctx = ctx;
        ctx.restore();
      },
    };
    const ch = new Chart(document.getElementById('bar-chart'), {
      type: 'bar',
      options: {
        animation: true,
        plugins: {
          legend: {
            display: true,
            labels: {
              color: '#70769b',
            },
          },
          customCanvasBackgroundColor: {
            color: '#02020E',
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label(tooltipItem) {
                const {raw} = tooltipItem;
                const toPercent = raw * 100;
                return `${toPercent.toFixed(2)}%`;
              },
            },
          },
        },
        scales: {
          x: {
            title: {
              color: '#70769b',
            },
            border: {
              display: false,
              color: '#70769b',
            },
            ticks: {
              color: '#70769b',
            },
            grid: {
              color: '#70769b',
            },
          },
          y: {
            title: {
              color: '#70769b',
            },
            border: {
              display: false,
              color: '#70769b',
            },
            grid: {
              color: '#70769b',
            },
            ticks: {
              callback(value, index, ticks) {
                const toPercent = value * 100;
                return `${toPercent.toFixed(0)}%`;
              },
              color: '#70769b',
            },
          },
        },
        responsive: true,
        // maintainAspectRatio: true,
      },
      data: {
        labels: xAxisLabels,
        datasets: yAxisLabels,
      },
      plugins: [plugin],
    });
    // ch.defaults.borderColor = '#e0e0e0';
    // ch.defaults.color = '#70769b';
    window.chart = ch;
    setChart(ch);
  };

  /**
   * Update bar chart with new data.
   * @param {object} ch - Chart Object
   * @param {string[]} newLabels - New labels for chart
   * @param {object[]} newDatasets - New datasets for chart
   * @param {string} newDatasets[].label - Label for dataset
   * @param {number[]} newDatasets[].data - Data for bars in dataset
   * @param {string} newDatasets[].backgroundColor - Background color for bars in dataset
   */
  const updateChart = (ch, newLabels, newDatasets) => {
    if (isEqual(ch.data.labels, newLabels) && isEqual(ch.data.datasets, newDatasets)) {
      // console.log('chart is equal');
      return;
    }
    if (ch.data.labels.length !== newLabels.length) {
      // eslint-disable-next-line no-param-reassign
      ch.data.labels = newLabels;
      // eslint-disable-next-line no-param-reassign
      ch.data.datasets = newDatasets;
      ch.update();
      // console.log('chart update');
      return;
    }

    newLabels.forEach((label, index) => {
      if (label === ch.data.labels[index]) {
        return;
      }
      if (label !== ch.data.labels[index]) {
        // eslint-disable-next-line no-param-reassign
        ch.data.labels[index] = label;
      }
    });
    newDatasets.forEach((dataset, index) => {
      dataset.data.forEach((d, i) => {
        if (d === ch.data.datasets[index].data[i]) {
          return;
        }
        if (d !== ch.data.datasets[index].data[i]) {
          // eslint-disable-next-line no-param-reassign
          ch.data.datasets[index].data[i] = d;
        }
      });
    });
    // console.log('chart update');
    ch.update();
  };

  /**
   * Initialize chart package and setup bar chart
   */
  useEffect(() => {
    if (!chart && Object.values(data ?? {})?.length) {
      setupBarChart(data);
    }

    return () => {
      if (chart && chart.destroy) {
        chart.destroy();
      }
      setChart(null);
    };
  }, []);

  /**
   * Resize chart on window resize or new data prints to chart.
   */
  useEffect(() => {
    const resizeChart = () => {
      if (chart) {
        chart.resize();
      }
    };
    if (chart) {
      window.addEventListener('afterprint', resizeChart);
    }

    return () => {
      window.removeEventListener('afterprint', resizeChart);
    };
  }, [chart]);

  /**
   * Update xAxis and yAxis values when data changes.
   */
  useEffect(() => {
    if (!chart) {
      return;
    }
    const {xAxisLabels, yAxisLabels} = configureMongoDataToChartData(data);
    setXAxisValues(xAxisLabels);
    setYAxisValues(yAxisLabels);
  }, [data, currentDate]);

  /**
   * Update chart when xAxis or yAxis values change.
   */
  useEffect(() => {
    if (!chart) return;
    updateChart(chart, xAxisValues, yAxisValues);
  }, [xAxisValues, yAxisValues]);

  return (
    <div className="chart-container" style={{position: 'relative', height: '40vh', width: '100%'}}>
      <canvas id="bar-chart" style={{width: '100%', height: '100%'}} />
    </div>
  );
};

export default BarChart;
