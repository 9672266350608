/* eslint-disable import/no-unresolved */
import React, {useState, useEffect} from 'react';
import {useWorkerizedReducer} from 'use-workerized-reducer/react';
import axios from 'axios';
import {io} from 'socket.io-client';
import ReactDOM from 'react-dom/client';
import {Routes, Route} from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import {applyTheme} from './themes/utils';
import Dashboard from './Pages/Dashboard';
import Home from './Pages/Home';
import Scanners from './Pages/Scanners';
import AccessDenied from './Pages/AccessDenied';
import Logout from './Pages/Logout';
import NotFound from './Pages/NotFound';
import {createStateViewCSS, setupGroups, join} from './Helpers/formatData';

import './App.css';

const {REACT_APP_STANDALONE, REACT_APP_WATCHLIST_URL} = process.env;

// Spin up the worker running the reducers.
const w = new Worker(new URL('./worker.js', import.meta.url), {
  type: 'module',
});
window.w = w;

const initialState = {
  groupCollection: {},
  groups: null,
  isConnected: false,
  didConnect: false,
  loadingGroups: true,
  initialized: false,
  updatedCount: 0,
  chartSettings: {},
  showChart: false,
  symbolData: {},
  symbolToChart: null,
  groupToChart: null,
  secondaryGroups: [],
  optionToView: null,
  inView: 0,
};

axios.defaults.baseURL = REACT_APP_WATCHLIST_URL ?? 'https://f2-admin-app.test/api';

const App = () => {
  // const [theme, setTheme] = useState(localStorage.getItem('theme') ?? 'light');
  const [theme, setTheme] = useState('dark');
  const [wsSocket, setWsSocket] = useState(null);
  const [state, dispatch] = useWorkerizedReducer(w, 'mainReducer', initialState);
  const groupCollectionRef = React.useRef(state.groupCollection);
  groupCollectionRef.current = state.groupCollection;

  useEffect(() => {
    applyTheme(theme);
  }, [theme]);

  // Set up the websocket connection
  useEffect(() => {
    const url = new URL(window.location.href);
    let token = url.searchParams.get('token');
    if (token) {
      localStorage.setItem('scanner-sso', token);
    } else {
      token = localStorage.getItem('scanner-sso');
    }
    if (token && !wsSocket) {
      const socket = io(process.env.REACT_APP_WEBSOCKET_URL, {
        reconnectionDelayMax: 5000,
        auth: {
          token: localStorage.getItem('scanner-sso'),
        },
        transports: ['websocket', 'polling'],
      });
      setWsSocket(socket);
    }
  }, []);

  const reconnectSocket = () => {
    const acc = [{year: 'numeric'}, {month: '2-digit'}, {day: '2-digit'}];
    const today = join(new Date(), acc, '-');
    if (wsSocket && state?.groups?.length) {
      state.groups?.forEach((group) => {
        const {group: rbGroup, type: rbType, date} = group;
        const scannerType = rbType ? decodeURIComponent(rbType) : 'tickalert';
        const groupToJoin = {
          group: `${decodeURIComponent(rbGroup)}`,
        };
        if (date) {
          groupToJoin[date] = date ?? today;
        }
        wsSocket?.emit(`${scannerType}:join`, groupToJoin);
      });
    }
  };


  useEffect(() => {
    if (wsSocket) {
      wsSocket?.on('connect', () => {
        dispatch({type: 'SET_CONNECTED'});
        dispatch({type: 'SET_INITIALIZED'});
      });

      wsSocket?.on('disconnect', (reason) => {
        dispatch({type: 'SET_DISCONNECTED'});
        if (reason === 'io server disconnect') {
          // the disconnection was initiated by the server, you need to reconnect manually
          wsSocket?.connect();
          dispatch({type: 'SET_CLIENT_DISCONNECTED'});
        }
      });

      wsSocket?.on('error', (error) => {
        console.error('Server socket.io error:', error);
      });

      wsSocket?.on('connect_error', (error) => {
        console.log('Connect Error:', error);
        dispatch({type: 'SET_INITIALIZED'});
      });

      wsSocket?.on('settings:scanner-access', (scannersData) => {
        dispatch({type: 'SET_SCANNERS', payload: scannersData});
      });

      wsSocket?.on('settings:user', (userData) => {
        dispatch({type: 'SET_USER', payload: userData});
      });

      wsSocket?.on(`stateview:update`, (res) => {
        const {data, group} = res;
        if (!group || !data) return;
        // checkIfUpdateIsNewData(res);
        dispatch({type: 'UPDATE_STATEVIEW', payload: {group, data}});
      });

      wsSocket?.on(`tickalert:update`, (res) => {
        const {data, group} = res;
        if (!group || !data) return;
        dispatch({type: 'UPDATE_TICKALERT', payload: {group, data}});
      });

      wsSocket?.on(`stateview:refresh`, (res) => {
        const {group, data} = res;
        if (!group || !data) return;
        dispatch({type: 'RESET_GROUPS', payload: {group, data}});
      });

      wsSocket?.on(`tickalert:refresh`, (res) => {
        const {group, data} = res;
        if (!group || !data) return;
        dispatch({type: 'RESET_GROUPS', payload: {group, data}});
      });

      wsSocket?.on(`stateview:join`, (res) => {
        const {group, settings, data} = res;
        if (!group || !settings || !data) {
          return;
        }
        dispatch({
          type: 'JOIN_GROUPS',
          payload: {group, settings, data, type: 'stateview', slug: window.location.pathname.replace('/scanners/', '')},
        });
        createStateViewCSS(settings);
      });

      wsSocket?.on(`stateview:delete`, (res) => {
        const {group, data, type} = res;
        if (!group || !data) {
          return;
        }
        dispatch({
          type: 'STATE_VIEW_DELETE',
          payload: {group, data, type},
        });
      });

      wsSocket?.on(`tickalert:join`, (res) => {
        const {group, settings, data} = res;
        if (!group || !settings || !data) {
          return;
        }
        dispatch({
          type: 'JOIN_GROUPS',
          payload: {group, settings, data, type: 'tickalert', slug: window.location.pathname.replace('/scanners/', '')},
        });
      });

      wsSocket?.on(`chart:get`, (res) => {
        const {settings, masterData} = res;
        if (!masterData) {
          return;
        }
        dispatch({
          type: 'SHOW_CHART',
          payload: {symbolData: masterData, chartSettings: settings},
        });
      });

      wsSocket.io.on('reconnection_attempt', () => {
        console.log('reconnection_attempt');
      });

      wsSocket?.io?.on('reconnect', () => {
        console.log('wsSocket.io reconnect');
        reconnectSocket();
      });
    }
    return () => {
      wsSocket?.close();
      dispatch({type: 'SET_CLIENT_DISCONNECTED'});
    };
  }, [wsSocket]);

  useEffect(() => {
    const url = new URL(window.location.href);
    let token = url.searchParams.get('token');
    // if (!token)
    //   token =
    //     'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7InJlZmVycmVyX2lkIjoibW9kMzkyIiwibmFtZSI6IkdhYmUxIiwiZW1haWwiOiJnYWJyaWVsQGdyYXBoZW0uY2EiLCJkaXNwbGF5X2ljb24iOiJodHRwczovL2FwcC5yYWdpbmdidWxsLmNvbS9hc3NldHMvaW1nL2FkbWluX2F2YXRhci5wbmciLCJyb2xlIjoiYWRtaW4iLCJzY2FubmVycyI6eyJhY2Nlc3NpYmxlIjpbInNreW5ldC1keW5hbWljLXdhdGNobGlzdCIsInVudXN1YWwtb3B0aW9ucy1hY3Rpdml0eS1zY2FubmVyIiwiZGFyay1wb29sLXNjYW5uZXIiLCJzcXVlZXplLXNjYW5uZXIiLCJ0cmFkZS1vZi10aGUtZGF5LXNjYW5uZXIiLCJoaWdoLW9jdGFuZS1zY2FubmVyIiwiMzYwLXNjYW5uZXIiLCJwb3N0bWFya2V0LXVwLWdhcHMiLCJqdy1tb21lbnR1bS1zY2FubmVyIiwid2FsbC1zdHJlZXQtYm9va2llLXNjYW5uZXIiLCJzbWFsbC1jYXAtc25pcGVyIiwidHJlbmRpbmctc3RvY2tzIl0sInN1YnNjcmliYWJsZSI6W10sInN1YnNjcmliZWQiOltdfSwiZnJlZV9hY2Nlc3MiOnRydWV9LCJpYXQiOjI2Njg0NTUwMjIsImV4cCI6MjY2ODQ4MzgyMn0.eLAVIjHuq3FcrHhQAk3iVa8ax_aRlvycnqTJ4ivi1E4';
    if (token) {
      localStorage.setItem('scanner-sso', token);
      url.searchParams.delete('token');
      window.history.replaceState({}, window.document.title, url.href);
    } else {
      token = localStorage.getItem('scanner-sso');
    }

    if (!token) {
      console.log('Disconnected. Reason: No user token supplied.');
      dispatch({type: 'SET_INITIALIZED'});
      dispatch({type: 'SET_DISCONNECTED'});
    }
  }, [state.initialized, state.isConnected]);

  useEffect(() => {
    if (state.isConnected && wsSocket && state.scannersData?.length) {
      const scannerId = window.location.pathname.replace('/scanners/', '');
      // console.log(`Firing scanner load event for ${scannerId}`);
      const allGroups  = setupGroups(scannerId, state.scannersData);
      let scannerGroups = allGroups;
      let notJoined = allGroups;
      const scannerConfig = state.scannersData?.find((s) => s.slug === scannerId);
      if (scannerConfig?.defaultGroup) {
        const {defaultGroup} = scannerConfig;
        const defaultGroupConfig = scannerConfig?.groups?.find((s) => s?.group === defaultGroup);
        if (defaultGroupConfig?.report) {
          const {report, mainReport} = defaultGroupConfig?.report;
          scannerGroups = scannerGroups?.filter((g) => g?.group === report || g?.group === mainReport || g?.group === defaultGroup);
          notJoined = notJoined?.filter((g) => g?.group !== report && g?.group !== mainReport && g?.group !== defaultGroup);
        } else {
          scannerGroups = scannerGroups?.filter((g) => g?.group === defaultGroup);
          notJoined = notJoined?.filter((g) => g?.group !== defaultGroup);
        }
        // if (defaultGroupData) {
        //   dispatch({type: 'SET_DEFAULT_GROUP', payload: {group: defaultGroupData}});
        // }
      }
      // Leave joined groups if any
      if (state.groups?.length) {
        state.groups?.map((group) => {
          if (group.joined) {
            // console.log('leaving ', group.group);
            wsSocket?.emit('group:leave', {group: `${decodeURIComponent(group.group)}`});
          }
        });
      }

      dispatch({type: 'CLEAR_GROUP_COLLECTION'});

      // Join groups if matched
      if (scannerGroups?.length) {
        const acc = [{year: 'numeric'}, {month: '2-digit'}, {day: '2-digit'}];
        const today = join(new Date(), acc, '-');
        // console.log('Attempting to join groups...');
        const joinedGroups = scannerGroups?.map((group) => {
          if (!group.sentJoin) {
            const {group: rbGroup, type: rbType, date} = group;
            const scannerType = rbType ? decodeURIComponent(rbType) : 'tickalert';

            const groupToJoin = {
              group: `${decodeURIComponent(rbGroup)}`,
            };

            if (date) {
              groupToJoin[date] = date ?? today;
            }
            // console.log('joining ', rbGroup);
            wsSocket?.emit(`${scannerType}:join`, groupToJoin);
            return {...group, sentJoin: true};
          }
          // console.log('already joined ', group.group);
          return group;
        });
        // console.log('joinedGroups', joinedGroups);
        dispatch({type: 'EMIT_JOIN', payload: {joinedGroups}});

        // Show popup modal 5 seconds after connected
        // setTimeout(() => {
        //   setModalShow(true);
        // }, 5000);

        // run adzerk script after scanner loaded
        // window.runAdzerk();
      }
    }
  }, [state.scannersData?.length]);

  useEffect(() => {
    // check if all groups have joined property === true
    if (state.groups?.length && state.groups?.every((group) => group.joined)) {
      state.groups?.forEach((group) => {
        const acc = [{year: 'numeric'}, {month: '2-digit'}, {day: '2-digit'}];
        const today = join(new Date(), acc, '-');
        if (group?.date === today) {
          wsSocket?.emit('stateview:listenforupdates', {group: group?.group});
        }
      });
    }
  }, [state.groups]);

  return (
    <div className={`${theme} flex h-screen max-h-screen bg-main-bg`}>
      <Routes>
        <Route
          path="/"
          element={
            <Dashboard theme={theme} setTheme={setTheme} state={state} dispatch={dispatch} wsSocket={wsSocket} />
          }
        >
          <Route index element={<Home />} />
          <Route path="/scanners/:id" element={<Scanners theme={theme} setTheme={setTheme} />} />
        </Route>
        <Route path="/logout" element={<Logout />} />
        <Route path="/access-denied" element={<AccessDenied />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

App.whyDidYouRender = true;
export default App;
