import {createContext} from 'react';

const SectorStrikeContext = createContext({
    optionsColumns: {},
    optionsAllowed: false,
    lockedColumns: [],
    layout: {},
    groups: [],
    groupCollection: {},
    currentDate: null,
});

export default SectorStrikeContext;