import React, {useContext} from 'react';
import BarChart from '../Chart/BarChart';
import ETFTable from './ETFTable';
import ETFGroupedScanner from './ETFGroupedScanner';
import {ReactComponent as SectorLogo} from '../../Icons/SectorLogo.svg';
import SectorStrikeContext from '../../Context/SectorStrikeContext';

const ETFPanel = ({panel}) => {
  const {groupCollection, currentDate} = useContext(SectorStrikeContext);
  const {group, viewLayout} = panel || {group: null, viewLayout: null};

  if (panel.view === 'etf-table') {
    return (
      <div className="flex flex-col gap-y-2 w-full h-full">
        <div className="p-2 h-2/5 w-full">
          <SectorLogo />
        </div>
        <div className="h-3/5 w-full">
          {!group || !viewLayout || !groupCollection?.[group] ? null : (
            <ETFTable
              data={groupCollection[group]?.data}
              groupCollection={groupCollection}
              viewLayout={viewLayout}
              currentDate={currentDate}
            />
          )}
        </div>
      </div>
    );
  }

  if (panel.view === 'bar-chart') {
    if (!group || !viewLayout || !groupCollection?.[group]) {
      return null;
    }
    return <BarChart data={groupCollection[group]?.data} viewLayout={viewLayout} />;
  }
  
  if (panel.view === 'grouped-table') {
    const {sectorCollection, indexCollection} = panel || {
      sectorCollection: null,
      indexCollection: null,
    };
    if (!sectorCollection || !indexCollection || !group || !viewLayout || !groupCollection?.[group]) {
      return null;
    }
    return (
      <ETFGroupedScanner
        sectorData={groupCollection[sectorCollection]?.data}
        indexData={groupCollection[indexCollection]?.data}
        data={groupCollection[group]?.data}
        dataTypes={groupCollection[group]?.dataTypes}
        viewLayout={viewLayout}
        group={group}
      />
    );
  }
  return null;
};

export default ETFPanel;
