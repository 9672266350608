import React, {useState, useEffect} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import dayjs from 'dayjs';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBoltLightning} from '@fortawesome/free-solid-svg-icons';
import {isEqual, orderBy as lodashOrderBy} from 'lodash';

const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.extend(customParseFormat);

const styles = {
  cellFill: {
    green10to8: '#57BB89',
    green8to6: '#79C9A2',
    green6to4: '#9BD7B9',
    green4to2: '#BCE4D1',
    green2to0: '#EFF9F4',
    red0to2: '#FAE4E3',
    red2to4: '#F5CAC7',
    red4to6: '#F0B0AB',
    red6to8: '#EB968F',
    red8to10: '#E67C73',
    default: '#fff',
  },
};

/**
 * Calculate background color based on cell value
 * @param {number} cellValue - Cell value to calculate background color for
 * @returns {string} Background color
 */
const calculateBackgroundColor = (cellValue) => {
  if (!cellValue) return '';
  if (cellValue > 8) {
    return styles.cellFill.green10to8;
  }
  if (cellValue > 6 && cellValue <= 8) {
    return styles.cellFill.green8to6;
  }
  if (cellValue > 4 && cellValue <= 6) {
    return styles.cellFill.green6to4;
  }
  if (cellValue > 2 && cellValue <= 4) {
    return styles.cellFill.green4to2;
  }
  if (cellValue > 0.5 && cellValue <= 2) {
    return styles.cellFill.green2to0;
  }
  if (cellValue <= 0.5 && cellValue >= -0.5) {
    return styles.cellFill.default;
  }
  if (cellValue < -0.5 && cellValue >= -2) {
    return styles.cellFill.red0to2;
  }
  if (cellValue < -2 && cellValue >= -4) {
    return styles.cellFill.red2to4;
  }
  if (cellValue < -4 && cellValue >= -6) {
    return styles.cellFill.red4to6;
  }
  if (cellValue < -6 && cellValue >= -8) {
    return styles.cellFill.red6to8;
  }
  if (cellValue < -8) {
    return styles.cellFill.red8to10;
  }
  return styles.cellFill.default;
};

const ETFTable = ({data, viewLayout, currentDate, groupCollection}) => {
  const [xAxisHeaders, setXAxisHeaders] = useState([]);
  const [yAxisHeaders, setYAxisHeaders] = useState([]);
  const [activeSectors, setActiveSectors] = useState([]);

  /**
   * Create xAxis headers and sort headers by orderBy
   * @param {array} tableData - Array of objects with ticker data
   * @param {object} xAxis - xAxis options 
   * @param {string[]} xAxis.keys - Array of keys to use for xAxis
   * @param {string} xAxis.orderBy - Key to order xAxis by
   * @returns {array}
   */
  const setupXAxis = (tableData, xAxis) => {
    if (!xAxis?.keys?.length) {
      return [];
    }
    const {keys: xAxisKeys, orderBy} = xAxis;
    const tableHeadersReduced = tableData.reduce((acc, ticker) => {
      xAxisKeys.forEach((key) => {
        if (ticker?.[key]?.value) {
          acc.push({label: ticker?.[key]?.value, key, orderBy: Number(ticker?.[orderBy ?? 'nb60dChange']?.value) ?? 0});
        }
      });
      return acc;
    }, []);

    const SPYorSPX = tableHeadersReduced.findIndex((h) => h?.label === 'SPY' || h?.label === 'SPX');
    if (SPYorSPX > -1) {
      // Move to end of array
      const removedFromArray = tableHeadersReduced.splice(SPYorSPX, 1)[0];
      const sortedTableData = lodashOrderBy(tableHeadersReduced, ['orderBy'], ['asc']);
      return [...sortedTableData, removedFromArray];
    }
    return lodashOrderBy(tableHeadersReduced, ['orderBy'], ['asc']);
  };

  /**
   * Create yAxis headers, sort headers by orderBy and filter active sectors
   * @param {object} yAxisOptions - yAxis options
   * @param {Object[]} yAxisOptions.keys - Array of Objects to use for yAxis options
   * @param {string} yAxisOptions.keys[].type - Type of yAxis key (time-period or active-sectors)
   * @param {string} yAxisOptions.keys[].key - Key to use for yAxis
   * @param {string} yAxisOptions.keys[].group - Group to use for yAxis (if type is active-sectors)
   * @param {string} yAxisOptions.keys[].allowedSymbols - Array of allowed symbols for yAxis (if type is active-sectors)
   * @param {string} yAxisOptions.keys[].groupToCompare - Group to compare for yAxis (if type is active-sectors)
   * @param {string} yAxisOptions.initialInterval - Initial interval for yAxis (if type is time-period)
   * @param {number} yAxisOptions.interval - Interval for yAxis  (if type is time-period)
   * @param {string} yAxisOptions.orderBy - Key to order yAxis by
   * @param {string} date - Date to use for yAxis
   * @returns {object} - Object with yAxis and active sectors
   */
  const setupYAxis = (yAxisOptions, date) => {
    const yAxis = [];
    let sectors = [];
    if (!yAxisOptions?.keys?.length) {
      return {yAxis, sectors};
    }
    const {keys: yAxisKeys, initialInterval} = yAxisOptions;

    let initialTimePeriod = initialInterval;
    const today = date ? dayjs(date, 'YYYY-MM-DD') : dayjs();

    yAxisKeys.forEach((k) => {
      if (k.type === 'time-period') {
        const label = !initialTimePeriod
          ? today.add(1, 'month').startOf('month').format('MMMM')
          : today.subtract(initialTimePeriod, 'days').add(1, 'month').startOf('month').format('MMMM');
        yAxis.push({label, key: k.key, type: 'time-period'});
        initialTimePeriod += k.interval;
        return;
      }
      if (k.type === 'active-sectors') {
        yAxis.push({
          label: '',
          key: null,
          type: 'active-sectors',
        });
        const {groupToCompare} = k;
        const {key: keyToCompare, group, allowedSymbols} = groupToCompare;
        if (groupCollection[group]?.data) {
          const collectionData = groupCollection[group]?.data;
          const dataValues = Object.values(collectionData ?? {});
          if (!dataValues?.length) {
            return;
          }
          sectors = dataValues.reduce((a, d) => {
            const value = d?.[keyToCompare]?.value;
            if (value && !a.includes(value)) {
              if (!allowedSymbols?.length || allowedSymbols.includes(value)) {
                return [...a, value];
              }
            }
            return a;
          }, []);
        }
      }
    });
    // Take last row and move to bottom after reverse order of months
    const yAxisClone = [...yAxis];
    const lastRow = yAxisClone.splice(yAxis.length - 1, 1)[0];
    const reverseDates = yAxisClone.reverse();
    reverseDates.push(lastRow)
    return {yAxis: reverseDates, sectors};
  };

  /**
   * Setup xAxis and yAxis headers
   */
  useEffect(() => {
    const tableData = Object.values(data ?? {});

    if (!tableData?.length) return;
    const {xAxis, yAxis} = viewLayout;
    const xAxisValues = setupXAxis(tableData, xAxis);
    const {yAxis: yAxisValues, sectors} = setupYAxis(yAxis, currentDate);

    setXAxisHeaders(xAxisValues);
    setYAxisHeaders(yAxisValues);
    setActiveSectors(sectors);
  }, [data, viewLayout, currentDate]);

  return (
    <div className="scanner etfscanner">
      <TableContainer component={Paper}>
        <Table stickyHeader sx={{minWidth: 0}} aria-labelledby="tableTitle" size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" padding="normal">
                <span className="cell-filler invisible">TIC</span>
              </TableCell>
              {xAxisHeaders?.map((header, index) => (
                <TableCell key={header?.label} align="center" padding="normal">
                  {header?.label}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell align="center" padding="normal">
                <span className="cell-filler invisible">TIC</span>
              </TableCell>
              {xAxisHeaders?.map((header, index) => (
                <TableCell key={header?.label} align="center" padding="normal">
                  Return
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {yAxisHeaders?.map((header) => (
              <TableRow key={header?.label} hover tabIndex={-1}>
                <TableCell align="center" padding="normal" className="whitespace-nowrap">
                  <span className="scanner-item etf-y-axis-header">{header?.label}</span>
                </TableCell>
                {xAxisHeaders?.map((xHeader, index) => {
                  if (header?.type === 'time-period') {
                    if (!data?.[xHeader?.label]?.[header?.key]?.value) {
                      return (
                        <TableCell
                          key={`${header?.label}-${xHeader?.label}`}
                          align="center"
                          padding="normal"
                          className="whitespace-nowrap"
                        >
                          <span className="invisible"> 00% </span>
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell
                        key={`${header?.label}-${xHeader?.label}`}
                        align="center"
                        padding="normal"
                        className="whitespace-nowrap"
                        style={{
                          backgroundColor: calculateBackgroundColor(data?.[xHeader?.label]?.[header?.key]?.value),
                        }}
                      >
                        <span className="scanner-item">{data?.[xHeader?.label]?.[header?.key]?.value}%</span>
                      </TableCell>
                    );
                  }
                  if (header?.type === 'active-sectors') {
                    if (!activeSectors?.length || !activeSectors.includes(xHeader?.label)) {
                      return (
                        <TableCell
                          key={`${header?.label}-${xHeader?.label}`}
                          align="center"
                          padding="normal"
                          className="whitespace-nowrap"
                        >
                          <span className="invisible"> 00% </span>
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell
                        key={`${header?.label}-${xHeader?.label}`}
                        align="center"
                        padding="normal"
                        className="whitespace-nowrap"
                        // style={{
                        //   backgroundColor: calculateBackgroundColor(data?.[xHeader?.label]?.[header?.key]?.value),
                        // }}
                      >
                        <span className="scanner-item">
                          <FontAwesomeIcon className="text-[#fded00]" icon={faBoltLightning} />
                        </span>
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell>
                      <span />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ETFTable;
