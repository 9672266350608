import React, {useState, useEffect, useContext} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import MemoizedScannerCell from '../Scanner/ScannerCell';
import SectorStrikeContext from '../../Context/SectorStrikeContext';
import AppContext from '../../Context/AppContext';

const ETFGroupedScanner = ({data, dataTypes, viewLayout, sectorData, indexData, group}) => {
  const {optionsAllowed, optionsColumns, lockedColumns, groups, groupCollection} = useContext(SectorStrikeContext);
  const {handleAddToWatchlist, getSymbolData} = useContext(AppContext);

  const [primarySymbolData, setPrimarySymbolData] = useState(null);
  const [groupedData, setGroupedData] = useState({
    keys: [],
    data: {},
  });
  const [columns, setColumns] = useState([]);

  const handleClickSymbol = (key = null, symbol = null) => {
    if (symbol && key === 'Symbol') {
      // if (displayChartDisabledMessage) {
      //   // eslint-disable-next-line no-alert
      //   alert(
      //     'Charts are offline to help increase SAM speed. We will have these up and running soon. Thanks for your patience!',
      //   );
      //   return;
      // }
      getSymbolData(symbol, group);
    }
  };

  useEffect(() => {
    const {xAxis} = viewLayout;
    setColumns(xAxis);
  }, [viewLayout]);

  useEffect(() => {
    const tableData = Object.values(data ?? {});
    const secData = Object.values(sectorData ?? {});

    const {groupBy, primarySymbols, orderBy: groupOrderBy} = viewLayout;
    if (!groupBy || !primarySymbols?.length || !tableData?.length || !secData?.length) {
      return;
    }
    const primarySymbol = secData?.find((s) => primarySymbols?.includes(s?.Symbol?.value));
    if (primarySymbol) {
      const primeSymbolData = indexData?.[primarySymbol?.Symbol?.value];
      if (primeSymbolData) {
        setPrimarySymbolData(primeSymbolData);
      }
    }

    const grouped = tableData?.reduce(
      (acc, ticker) => {
        const key = ticker[groupBy]?.value;
        if (!key) return acc;
        if (!acc.data?.[key]) {
          acc.data[key] = [];
        }
        if (!acc.keys?.includes(key)) {
          acc.keys.push(key);
        }
        acc.data[key] = [...(acc.data[key] ?? []), ticker].sort((a, b) =>
          a[groupOrderBy]?.value > b[groupOrderBy]?.value ? 1 : -1,
        );
        return acc;
      },
      {
        keys: [],
        data: {},
      },
    );
    setGroupedData(grouped);
  }, [data, viewLayout, sectorData]);

  return (
    <div className="scanner grouped-table">
      <TableContainer sx={{maxHeight: '45vh'}} component={Paper}>
        <Table stickyHeader sx={{minWidth: 0}} aria-labelledby="tableTitle" size="small">
          <TableHead>
            {/* Headers Symbol */}
            <TableRow>
              <TableCell align="center" padding="normal">
                Sector
              </TableCell>
              {columns?.map((header, index) => (
                <TableCell key={header?.label} align="center" padding="normal">
                  {header?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* SPX/SPY Row */}
            <TableRow className="last-child primary-symbol-row">
              <TableCell align="center" rowSpan={1}>
                <span className="symbol-item cursor-default symbol-item-no-link">
                  {primarySymbolData?.Symbol?.value}
                </span>
              </TableCell>
              {columns?.map((header, i) => {
                const dataType = dataTypes?.find((dt) => dt.key === header?.key);
                if (!dataType) {
                  return (
                    <TableCell
                      key={`${header?.label}-${primarySymbolData?.Symbol?.value}`}
                      align="center"
                      padding="normal"
                      className="whitespace-nowrap"
                    />
                  );
                }
                return (
                  <MemoizedScannerCell
                    key={`${header?.label}-${primarySymbolData?.Symbol?.value}`}
                    rowValue={primarySymbolData}
                    dataType={dataType}
                    dataTypes={dataTypes}
                    overrideKey={null}
                    allowedChart={groupCollection[group]?.allowedChart}
                    allowedWatchlist={false}
                    groupName={group}
                    handleAddToWatchlist={handleAddToWatchlist}
                    handleClickSymbol={handleClickSymbol}
                    addedToWatchlist={false}
                    useAI={false}
                    openAIModal={null}
                    optionsColumns={optionsColumns}
                    optionsAllowed={optionsAllowed}
                    lockedColumns={lockedColumns}
                  />
                );
              })}
            </TableRow>
            {/* Main Rows */}
            {groupedData.keys.map((key) => {
              if (!groupedData.data?.[key]?.length) {
                return null;
              }
              return (
                <>
                  <TableRow className="last-child" key={key}>
                    <TableCell
                      align="center"
                      className="sector-symbol-row"
                      rowSpan={groupedData?.data?.[key].length + 1}
                    >
                      <span className="symbol-item cursor-default symbol-item-no-link">{key}</span>
                    </TableCell>
                  </TableRow>
                  {groupedData?.data?.[key]?.map((rowValue, index) => (
                    <TableRow
                      className={`${!groupedData?.data?.[key][index + 1] ? 'last-child' : ''}`}
                      key={rowValue?.Symbol?.value}
                    >
                      {columns?.map((header, i) => {
                        const dataType = dataTypes?.find((dt) => dt.key === header?.key);
                        if (!dataType) {
                          return (
                            <TableCell
                              key={`${header?.label}-${rowValue?.Symbol?.value}`}
                              align="center"
                              padding="normal"
                              className="whitespace-nowrap"
                            />
                          );
                        }
                        return (
                          <MemoizedScannerCell
                            rowValue={rowValue}
                            dataType={dataType}
                            dataTypes={dataTypes}
                            overrideKey={null}
                            allowedChart={groupCollection[group]?.allowedChart}
                            allowedWatchlist={false}
                            groupName={group}
                            handleAddToWatchlist={handleAddToWatchlist}
                            handleClickSymbol={handleClickSymbol}
                            addedToWatchlist={false}
                            useAI={false}
                            openAIModal={null}
                            optionsColumns={optionsColumns}
                            optionsAllowed={optionsAllowed}
                            lockedColumns={lockedColumns}
                          />
                        );
                      })}
                    </TableRow>
                  ))}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ETFGroupedScanner;
